export const normalizeSearchText = (text: string) =>
    text.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');

export const filterBySearchTerm = (
    dataToFilter: Record<string, string>,
    searchTermToFilter: string,
): Record<string, string> => {
    if (!dataToFilter) {
        return {};
    }

    searchTermToFilter = normalizeSearchText(searchTermToFilter);

    return Object.fromEntries(
        Object.entries(dataToFilter).filter(
            ([key, value]) =>
                normalizeSearchText(key).includes(searchTermToFilter) ||
                normalizeSearchText(value).includes(searchTermToFilter),
        ),
    );
};
