import React from 'react';
import { NodeRendererProps } from 'react-arborist';
import { Icon } from '../../Icon';
import { Button } from '../../Button';
import { CqWord } from '../../../utils/word';

export function Node({ node, style, dragHandle }: NodeRendererProps<any>) {
    /* This node instance can do many things. See the API reference. */
    return (
        <div
            className="flex justify-between text-gray-600 hover:text-blue-700"
            style={style}
            ref={dragHandle}
        >
            <div onClick={() => node.toggle()} className={`${!node.isLeaf && 'cursor-pointer'}`}>
                {node.isLeaf ? (
                    <></>
                ) : (
                    <Icon className="inline-block" size={6} name="keyboard_arrow_down" />
                )}
                {node.data.name}
            </div>
            <div className="flex space-x-2">
                {node.isLeaf && (
                    <>
                        {/* <Link to={`${url}/condition/${node.data.key}`}> */}
                        <Button
                            onClick={() => {
                                CqWord.addHtmlText(
                                    `\${renderIf('project.${
                                        node.data.key
                                    }', '=', 'value','block_type_${node.data.key.replaceAll(
                                        '.',
                                        '_',
                                    )}')} <br />
									\${block_type_${node.data.key.replaceAll(
                                        '.',
                                        '_',
                                    )}}
									<br /> <br />
									\${/block_type_${node.data.key.replaceAll(
                                        '.',
                                        '_',
                                    )}} `,
                                );
                            }}
                            size="small"
                        >
                            If
                        </Button>
                        {/* </Link> */}
                        <Button
                            onClick={() =>
                                CqWord.addHtmlText(`\${value('project.${node.data.key}')}`)
                            }
                            size="small"
                        >
                            Value
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
}
