import * as qs from 'qs';

export function make(path: string, params: any = {}): string {
    const query = toQueryString(params);

    if (!query) return path;

    return encodeURI(`${path}${path.includes('?') ? '&' : '?'}${query}`);
}

export function toQueryString(params: any): string {
    return qs.stringify(params, {
        arrayFormat: 'brackets',
        encoder(value) {
            if (typeof value === 'boolean') {
                return value ? '1' : '0';
            }

            return value;
        },
    });
}

export function fromQueryString(query: string): qs.ParsedQs {
    return qs.parse(query, { ignoreQueryPrefix: true });
}
