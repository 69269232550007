import React, { useEffect, useState } from 'react';
import { Page } from '../components/Page';
import { CqWord } from '../utils/word';
import { Button } from '../components/Button';
import { TypeAnimation } from 'react-type-animation';
import { http } from '../utils/http';
import { Icon } from '../components/Icon';
import Loading from '../components/Loading';

export function AIPage() {
    const [selectedText, setSelectedText] = useState('');
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');
    const [isAnimationDone, setAnimationDone] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        CqWord.getDocument(() => console.log('Document loaded'));
        CqWord.documentSelectionChanged((text: string) => {
            if (text.length > 0) {
                setSelectedText(text);
            }
        });
        return () => {
            CqWord.stopDocumentSelectionChanged();
        };
    }, []);

    const submit = () => {
        setIsLoading(true);
        http.get(`/api/plugin/edit-text?selectedText=${selectedText}&prompt=${prompt}`)
            .then((response: unknown) => {
                setResponse(response as string);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setResponse('An error occurred while generating response');
            });
    };

    const trackChangesInDocument = async (modifiedText: string) => {
        CqWord.diffsSelectedText(modifiedText);
    };

    return (
        <Page>
            <Headline>AI tools</Headline>
            <Description>Use AI to help write content</Description>
            <Box>
                {selectedText.length > 0 ? (
                    <>
                        <div className="flex justify-between ">
                            <small
                                onClick={() =>
                                    CqWord.getSelectedText().then((values: unknown) =>
                                        setSelectedText(values as string),
                                    )
                                }
                                className="text-blue-600 cursor-pointer absolute right-0"
                                style={{ top: '-1.5rem' }}
                            >
                                Get text
                            </small>
                        </div>
                        <div
                            className=" text-sm text-justify"
                            style={{
                                transition: 'all 0.5s ease-in-out',
                                opacity: selectedText.length > 0 ? 1 : 0,
                            }}
                        >
                            {selectedText.slice(0, 200)} {selectedText.length > 200 && '...'}
                        </div>
                    </>
                ) : (
                    <div className="text-gray-600 text-center flex items-center flex-col space-y-3">
                        <Icon name="edit" size={8} />
                        <p className="text-md">Select text in the document to get started.</p>
                        <p
                            onClick={() =>
                                CqWord.getSelectedText().then((values: unknown) =>
                                    setSelectedText(values as string),
                                )
                            }
                            className="text-blue-600 font-bold cursor-pointer"
                        >
                            Get selected text
                        </p>
                    </div>
                )}
            </Box>

            {selectedText.length > 0 && (
                <div className="mt-4">
                    <label className="block text-gray-700">Your prompt</label>
                    <textarea
                        placeholder="Write your prompt here..."
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        className="w-full h-32  border-2 border-gray-300 rounded-lg p-2 mt-2 focus:outline-none"
                    />
                    <Button
                        icon="ai"
                        disabled={prompt.length == 0 || isLoading}
                        className="w-full mt-2"
                        onClick={submit}
                    >
                        Generate
                    </Button>
                </div>
            )}

            {isLoading && (
                <div className="flex justify-center flex-col items-center mt-8" role="status">
                    <Loading />
                    <span>Response is generating...</span>
                </div>
            )}
            {response && !isLoading && (
                <>
                    <Box>
                        <TypeAnimation
                            key={response}
                            sequence={[
                                () => setAnimationDone(false),
                                response,
                                () => setAnimationDone(true),
                            ]}
                            wrapper="span"
                            cursor={true}
                            speed={95}
                        />
                    </Box>

                    <Button
                        className="block w-full mt-4"
                        onClick={() => trackChangesInDocument(response)}
                    >
                        Use in document
                    </Button>
                </>
            )}
        </Page>
    );
}

const Headline: React.FC = ({ children }) => (
    <h1 className="text-xl font-semibold text-gray-800">{children}</h1>
);

const Description: React.FC = ({ children }) => <p className="text-gray-500 ">{children}</p>;

const Box: React.FC = ({ children }) => (
    <div className="bg-gray-100 rounded-lg py-4 px-2 mt-4">{children}</div>
);
