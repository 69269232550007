import React, { FC, ReactNode } from 'react';

export const Field: FC<{
    name: string;
    label?: string;
    className?: string;
    errorMessage?: string;
    right?: ReactNode;
}> = ({ name, children, label, errorMessage, className, right }) => {
    return (
        <div className={className}>
            <div className="min-h-20 relative">
                <div className="w-full min-h-20 pt-4">{children}</div>

                {label && (
                    <label
                        htmlFor={name}
                        className={`absolute top-0 block leading-none select-none ${
                            errorMessage ? 'text-red-700' : 'text-gray-600'
                        }`}
                    >
                        {label}
                    </label>
                )}
                {right && (
                    <div className="absolute top-0 right-0 leading-none select-none">{right}</div>
                )}
            </div>

            {errorMessage && (
                <p className="pt-2 text-sm text-red-700" role="alert">
                    {errorMessage}
                </p>
            )}
        </div>
    );
};
