import React, { FC, useState, ReactElement } from 'react';
import { Manager, Popper as ReactPopper, Reference } from 'react-popper';
import { Placement } from 'popper.js';
import OutsideClick from './OutsideClick';

export const Popper: FC<{
    placement?: Placement;
    handle: (props: { ref: React.Ref<any>; open: boolean; onClick: () => void }) => ReactElement;
    children: (props: {
        ref: React.Ref<any>;
        style: React.CSSProperties;
        open: () => void;
        close: () => void;
    }) => ReactElement;
}> = ({ placement = 'bottom', handle, children }) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <OutsideClick onOutsideClick={() => setOpen(false)}>
            <Manager>
                <Reference>
                    {({ ref }) => handle({ ref, open, onClick: () => setOpen(!open) })}
                </Reference>

                {open && (
                    <ReactPopper placement={placement}>
                        {({ ref, style }) =>
                            children({
                                ref,
                                style,
                                open: () => setOpen(true),
                                close: () => setOpen(false),
                            })
                        }
                    </ReactPopper>
                )}
            </Manager>
        </OutsideClick>
    );
};
