import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Field } from './Field';
import { Button } from './Button';
import { TextInput, Type } from './TextInput';
import { Response } from '../utils/http';
import { useToasts } from './Toast';

interface Params<T> {
    values: T;

    errors: Partial<Record<keyof T, string[]>>;
    hasError: (name: keyof T) => boolean;
    firstError: (name: keyof T) => string | undefined;

    handleChange: <F extends keyof T>(name: F, value: T[F]) => void;
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

    dirty: boolean; // any values have been changed
    disabled?: boolean; // any values have been changed
    allowPristineSubmission: boolean; // allow to click submit even when no values have been changed
}

type Values = Record<string, any>;

/**
 * INTERNAL USE ONLY (internal = in this file)
 * Not typed properly as the types depend on <Form>.
 */
const FormContext = createContext<Params<Values>>({
    values: {},
    errors: {},
    hasError: () => false,
    firstError: () => undefined,
    handleChange: () => {},
    handleSubmit: () => {},
    dirty: false,
    allowPristineSubmission: false,
});

export function Form<T extends Values>({
    initialValues,

    onSubmit,
    onSuccess,
    onError,

    submitOnEnter = false,

    children,

    guard: guardEnabled = true,
    allowPristineSubmission = false,
}: {
    initialValues: T;

    onSubmit: (values: T) => Promise<Response<any>>;
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;

    submitOnEnter?: boolean;

    children: ReactNode;
    name?: string;
    allowPristineSubmission?: boolean;
    guard?: boolean;
}) {
    const history = useHistory();
    const { error } = useToasts();
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState<Params<T>['errors']>({});
    const [dirty, setDirty] = useState(false);
    const [guard, setGuard] = useState<string | null>(null);

    useEffect(() => {
        const unregister = history.block((arg) => {
            // if guard is not enabled, do not block
            if (!guardEnabled) {
                return;
            }

            // do not block when not touched
            if (!dirty) {
                return;
            }

            // do not block when going to the same route
            if (arg.pathname === history.location.pathname) {
                return;
            }

            // do not block if `pathname === guard`
            // because this means the user clicked
            // "Yes, close" in the guard modal
            if (arg.pathname === guard) {
                return;
            }

            setGuard(arg.pathname);

            return false;
        });

        return () => unregister();
    }, [history, dirty, guard, guardEnabled]);

    const submit = () => {
        onSubmit(values)
            .then((response) => {
                setDirty(false);
                setErrors({});

                if (onSuccess) {
                    onSuccess(response.data);
                }
            })
            .catch((e) => {
                setErrors(e?.response?.data?.errors || {});
                if (onError) {
                    onError(e?.response?.data?.errors);
                } else {
                    error('Please check validation errors');
                }
            });
    };

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    if (submitOnEnter) {
                        submit();
                    }
                }}
            >
                <FormContext.Provider
                    value={{
                        values,
                        errors,

                        hasError(name) {
                            return errors[name] !== undefined;
                        },

                        firstError(name) {
                            return errors[name]?.[0];
                        },

                        handleChange(name, value) {
                            setDirty(true);
                            // setValues({ ...values, [name]: value });
                            let newVal = Object.assign({}, values);
                            newVal[name] = value;
                            setValues(newVal);
                        },

                        handleSubmit(event) {
                            event.preventDefault();

                            submit();
                        },

                        dirty,
                        allowPristineSubmission,
                    }}
                >
                    {children}
                </FormContext.Provider>
            </form>
        </>
    );
}

const Form_TextInput: FC<{
    name: string;
    label: string;
    placeholder?: string;
    type?: Type;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
}> = ({ name, label, type = 'text', placeholder, className, autoFocus }) => {
    return (
        <FormContext.Consumer>
            {({ values, hasError, firstError, handleChange, disabled }) => {
                return (
                    <Field
                        name={name}
                        label={label}
                        errorMessage={firstError(name)}
                        className={className}
                    >
                        <TextInput
                            name={name}
                            type={type}
                            disabled={disabled}
                            value={values[name]}
                            onChange={(v) => {
                                handleChange(name, v);
                            }}
                            hasError={hasError(name)}
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                        />
                    </Field>
                );
            }}
        </FormContext.Consumer>
    );
};

const Form_Submit: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
    return (
        <FormContext.Consumer>
            {({ handleSubmit, dirty, allowPristineSubmission }) => (
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    className={className}
                    disabled={!allowPristineSubmission && !dirty}
                >
                    {children}
                </Button>
            )}
        </FormContext.Consumer>
    );
};

Form.TextInput = Form_TextInput;
Form.Submit = Form_Submit;
Form.Context = FormContext.Consumer;
