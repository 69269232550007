import React, { FC, createContext } from 'react';
import { Configuration, User } from '@contractool/schema';
import { registerErrorResponseInterceptor } from './http';
import useLocalStorage from '@rehooks/local-storage';
import { make } from './url';
import { useRequest } from './hooks/useRequest';
import { useState } from 'react';

export const USER_KEY = 'user';

export const AuthContext = createContext<{
    user: User | null;
    config: any;
    login: (user: User) => void;
    logout: () => void;
    update: (user: User) => void;
}>({
    user: null,
    config: null,
    login: () => {},
    logout: () => {},
    update: () => {},
});

export const AuthContextProvider: FC = ({ children }) => {
    const value = Object.assign({}, useAuthHandler());
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthHandler = () => {
    const [user, setUser, unsetUser] = useLocalStorage<User>(USER_KEY);

    const [errorResponse, setErrorResponse] = useState({});
    const [configuration, {refresh}] = useRequest<Configuration | undefined>(
        '/api/configuration',
        undefined,
        {},
        setErrorResponse,
    );
    const config = configuration === undefined ? errorResponse : configuration;

    // needs to be called synchronously (not within a useEffect)
    registerErrorResponseInterceptor((response) => {
        if (response?.status === 401) {
            //unsetUser();
        }
    });

    return {
        user,

        config,

        login(user: User) {
            setUser(user);
			refresh();
        },

        logout() {
			localStorage.removeItem('token');
            unsetUser();
        },

        update(user: User) {
            setUser(user);
        },
    };
};

export function sign(url: string): string {
    return make(url, { token: '' });
}
