import React from 'react';
import { Button } from '../../Button';
import { CqWord } from '../../../utils/word';

interface IField {
    label?: string;
    value: string;
    name?: string;
}

const Field: React.FC<
    IField & {
        barName?: string;
        selected: string;
        selectValue: (value: string) => void;
    }
> = ({ barName, label, value, name, selected, selectValue }) => {
    return (
        <li
            className={`py-3 cursor-pointer hover:bg-gray-150 ${
                selected === value || selected === name
                    ? 'bg-gray-150 border-r-2 border-blue-700'
                    : ''
            }`}
            onClick={() => {
                selectValue(value ?? name);
            }}
        >
            <div
                className={`flex items-center justify-between hover:text-blue-700 focus:text-blue-700 focus:outline-none ${
                    selected === value || selected === name ? 'text-blue-700 ' : 'text-gray-600'
                }`}
            >
                <div className="ml-3 leading-tighter select-none flex-1">
                    {barName === 'workflow' && value !== 'basic' && 'Workflow - '}
                    {label}
                </div>
                <div>
                    <Button
                        onClick={() => {
                            CqWord.addHtmlText(
                                `\${renderIf('project.fields.${value}', '=', 'value','block_type_${value.replaceAll(
                                    '.',
                                    '_',
                                )}')}<br />\${block_type_${value.replaceAll(
                                    '.',
                                    '_',
                                )}} <br /> <br />\${/block_type_${value.replaceAll('.', '_')}} `,
                            );
                        }}
                        size="small"
                        color="blue"
                        className="ml-3"
                    >
                        If
                    </Button>
                    <Button
                        onClick={() => CqWord.addHtmlText(`\${value('project.fields.${value}')}`)}
                        size="small"
                        color="blue"
                        className="ml-3"
                    >
                        Value
                    </Button>
                </div>
            </div>
        </li>
    );
};

export default Field;
