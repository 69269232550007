import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';

import TutorialDrafting from '../assets/images/Tutorial-Drafting.png';
import TutorialRedlining from '../assets/images/Tutorial-Redlining.png';
import TutorialIntegration from '../assets/images/Tutorial-Integration.png';
import TutorialRevolutionize from '../assets/images/Tutorial-Revolutionize.png';

const TutorialPage = ({ onSkip }) => {
    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    let sliderRef = useRef(null);
    return (
        <>
            <div className="flex justify-end px-8">
                <button className="py-4 text-md" type="button" onClick={onSkip}>
                    Skip
                    <Icon name="keyboard_arrow_right" size={6} className="pl-2 inline" />
                </button>
            </div>
            <Slider
                ref={(slider) => {
                    sliderRef = slider;
                }}
                {...settings}
            >
                <TutorialContainer
                    title="AI-Powered Drafting"
                    desc="Leverage AI to draft and refine contract language, saving time and improving accuracy."
                    buttonText="Next"
                    img={TutorialDrafting}
                    onClick={() => {
                        // @ts-ignore
                        sliderRef.slickNext();
                    }}
                />
                <TutorialContainer
                    title="AI-Powered Redlining"
                    desc="Automatically apply AI-generated suggestions as redlines/track changes in your document."
                    buttonText="Next"
                    img={TutorialRedlining}
                    onClick={() => {
                        // @ts-ignore
                        sliderRef.slickNext();
                    }}
                />
                <TutorialContainer
                    title="Effortless Integration"
                    desc="Seamlessly integrates with MS Word, allowing you to access all features without leaving your document"
                    buttonText="Next"
                    img={TutorialIntegration}
                    onClick={() => {
                        // @ts-ignore
                        sliderRef.slickNext();
                    }}
                />
                <TutorialContainer
                    title="Revolutionize Your Contract Management Today"
                    desc="Download Cequence Add-in and enhance your document drafting with AI-powered tools."
                    buttonText="Sign in"
                    img={TutorialRevolutionize}
                    onClick={() => {
                        onSkip();
                    }}
                />
            </Slider>
        </>
    );
};

export default TutorialPage;

const TutorialContainer = ({ title, desc, buttonText, img, onClick }) => {
    return (
        <div className="text-center px-8 space-y-6">
            <img src={img} alt="placeholder" />
            <h2 className="text-xl font-semibold">{title}</h2>
            <p className="pb-4 inline-block">{desc}</p>
            {buttonText && (
                <Button onClick={onClick} className="w-full">
                    {buttonText}
                </Button>
            )}
        </div>
    );
};
