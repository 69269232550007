const Diff = require('diff');

function countOccurrences(string, subString) {
    // Escape special characters in the subString to avoid regex interpretation
    const escapedSubString = subString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // Create a regular expression with the escaped subString and the global flag
    const regex = new RegExp(escapedSubString, 'g');
    // Use match() to find all occurrences of the subString in the string
    const matches = string.match(regex);
    // Return the number of matches found
    return matches ? matches.length : 0;
}

export const CqWord = {
    addHtmlText: (text: string, type?: 'Before' | 'After' | 'Start' | 'End' | 'Replace') => {
        Word.run((context) => {
            let selectionRange = context.document.getSelection();
            selectionRange.insertHtml(text, type ? type : 'Replace');
            return context.sync();
        });
    },

    getSelectedText: () => {
        return new Promise((resolve) => {
            Office.context.document.getSelectedDataAsync(
                Office.CoercionType.Text,
                function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        console.error(
                            'Action failed. Error: ' + asyncResult.error.message,
                            asyncResult,
                        );
                    } else {
                        console.log('Selected data: ' + asyncResult.value, asyncResult);
                        resolve(asyncResult.value);
                    }
                },
            );
        });
    },

    setSelectedText: (text: string) => {
        return new Promise((resolve) => {
            Office.context.document.setSelectedDataAsync(text, function (asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                    console.error(
                        'Action failed. Error: ' + asyncResult.error.message,
                        asyncResult,
                    );
                } else {
                    console.log('Selected data: ' + asyncResult.value, asyncResult);
                    resolve(asyncResult.value);
                }
            });
        });
    },

    documentSelectionChanged: (callback: (text: string) => void) => {
        Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, () => {
            Office.context.document.getSelectedDataAsync(
                Office.CoercionType.Text,
                (result: unknown) => {
                    //@ts-ignore
                    callback(result.value as string);
                },
            );
        });
    },

    stopDocumentSelectionChanged: () => {
        Office.context.document.removeHandlerAsync(
            Office.EventType.DocumentSelectionChanged,
            () => {
                console.log('Removed handler');
            },
        );
    },

    getDocument: (callback: (text: string) => void) => {
        Word.run(async (context) => {
            const body = context.document.body;

            // Queue a command to load the text in document body.
            // https://github.com/OfficeDev/office-js-snippets/blob/prod/samples/word/50-document/manage-body.yaml
            body.load('text');

            // Synchronize the document state by executing the queued commands, and return a promise to indicate task completion.
            await context.sync();

            console.log('Body contents (text): ' + body.text);

            callback(body.text);
        });
    },

    diffsSelectedText: async (newText) => {
        await Word.run(async (context) => {
            const paragraph: Word.Range = context.document.getSelection();
            const sentencesWord = paragraph.split(['.', '!', '?'], false, false);
            sentencesWord.load('text');

            await context.sync();

            if (!newText) return;

            // Prechadzanie kazdej starej vety : Word
            const runRedling = async () => {
                for (let index = 0; index < sentencesWord.items.length; index++) {
                    const oldSentence = sentencesWord.items[index].text;
                    const newSentence = newText.match(/[^.!?]+[.!?]/g)[index];
                    await context.sync();

                    let diff = [];
                    if (oldSentence && newSentence) {
                        diff = Diff.diffWordsWithSpace(oldSentence, newSentence);
                    }

                    // TODO: 1. Ak cela veta sa vymazala tak ju treba vymazat
                    console.log('diff', diff, newSentence);
                    if (diff.length === 0) {
                        sentencesWord.items[index].insertText('', 'Replace');
                        await context.sync();
                    }

                    // Prechadzanie kazdej zmeny
                    for (let i = 0; i < diff.length; i++) {
                        // ak je odstranene slovo, tak pozriet na dalsiu zmenu a nahradit to tym
                        if (diff[i].removed == true) {
                            if (diff[i].value == ' ') {
                                continue;
                            }

                            const find: Word.RangeCollection = sentencesWord.items[index].search(
                                diff[i].value,
                            );
                            find.load('text');
                            await context.sync();

                            // TODO: 2. Tu je niekde chyba , nejak to zle spocita a prida to na zle miesto

                            console.log('removed - find.items', find.items);
                            // Potrebujem zistit ktore index to je
                            if (find.items.length > 1) {
                                const sum = diff.reduce((accu, current, reduceIndex) => {
                                    const pattern = new RegExp('\\b' + diff[i].value + '\\b', 'i');
                                    const isWordIncluded = pattern.test(current.value);

                                    console.log(
                                        'isWordIncluded',
                                        current.value,
                                        diff[i].value,
                                        isWordIncluded,
                                        reduceIndex <= i,
                                    );
                                    // TODO: 3. Predpokladajme ze vo vete je viacero rovnakych slov

                                    if (reduceIndex <= i && isWordIncluded) {
                                        // TODO: POZOR NA TOTO, Mozno by to trebalo prerobit
                                        //if (current.removed !== true) {
                                        if (current.removed == true && reduceIndex < i) {
                                        } else {
                                            const occurrences = countOccurrences(
                                                current.value,
                                                diff[i].value,
                                            );
                                            accu = accu + occurrences; // Increment the accumulator if the condition is met
                                        }
                                    }
                                    return accu; // Always return the accumulator from the callback
                                }, 0); // Initial accumulator value set to 0

                                console.log('sum', sum);

                                if (sum == 0) {
                                    // If empty space
                                    if (find.items[0].text === ' ') {
                                        find.items[sum].insertText('', 'Replace');
                                    }
                                    await context.sync();
                                } else {
                                    // if next part has added then add this
                                    if (diff[i + 1] != null && diff[i + 1].added == true) {
                                        find.items[sum - 1].insertText(
                                            diff[i + 1].value,
                                            'Replace',
                                        );
                                        // if next part is empty space and then is added
                                    } else {
                                        find.items[sum - 1].insertText('', 'Replace');
                                    }
                                    await context.sync();
                                }
                            } else if (find.items.length <= 1 && find.items.length >= 0) {
                                // if next part has added then add this

                                if (diff[i + 1] != null && diff[i + 1].added == true) {
                                    find.items[0].insertText(diff[i + 1].value, 'Replace');
                                } else {
                                    find.items[0].insertText(' ', 'Replace');
                                }
                                await context.sync();
                            }
                        }

                        // Ak je pridane slovo
                        if (diff[i].added == true) {
                            // Zatial medzery vynechavame
                            if (diff[i].value == ' ') {
                                continue;
                            }

                            if (i == 0) {
                                sentencesWord.items[index].insertText(diff[i].value, 'Start');
                                await context.sync();
                            }
                            // a predchadzajuce slovo/slova su povodne
                            else if (diff[i - 1].added == false && diff[i - 1].removed == false) {
                                if (diff[i - 1].value == ' ' && i > 1) {
                                    // Predchadzajuce slovo je medzera, a pred pred je string
                                    // Predpokladame ze je zly search a treba nam spocitat kolko itemov mame
                                    await context.sync();
                                    const find = sentencesWord.items[index].search(
                                        ' ' + diff[i - 2].value,
                                        {
                                            matchWholeWord: false,
                                            ignoreSpace: false,
                                            ignorePunct: false,
                                        },
                                    );
                                    find.load('text');
                                    await context.sync();

                                    console.log(
                                        'sentencesWord.items[index].text',
                                        sentencesWord.items[index].text,
                                    );
                                    console.log('find.items', find.items);

                                    if (find.items.length > 1) {
                                        const sum = diff.reduce((accu, current, reduceIndex) => {
                                            // diff[i - 2].value is "the"
                                            const pattern = new RegExp(
                                                '\\b' + diff[i - 2].value + '\\b',
                                                'i',
                                            );
                                            const isWordIncluded = pattern.test(current.value);

                                            console.log(
                                                'isWordIncluded',
                                                current.value,
                                                diff[i - 2].value,
                                                isWordIncluded,
                                                reduceIndex <= i,
                                            );

                                            if (reduceIndex < i - 2 && isWordIncluded) {
                                                //if (current.added == true && current.removed == false || current.added == false && current.removed == false) {
                                                //if(current.removed == false){
                                                // ak sa vymenili slova, tak povodne slovo sa uz nenachadza v diffe takze ho musime vylucit

                                                // TODO: TOTO POTREBUJEME ZAPRACOVAT na ANY a THE

                                                //if ((diff[i - 2].added == true && diff[i - 3].removed == true) || (current.removed == true && diff[reduceIndex + 1].added == true)) {

                                                if (current.removed == true) {
                                                } else {
                                                    const occurrences = countOccurrences(
                                                        current.value,
                                                        diff[i - 2].value,
                                                    );
                                                    accu = accu + occurrences; // Increment the accumulator if the condition is met
                                                }

                                                //}
                                            }
                                            return accu; // Always return the accumulator from the callback
                                        }, 0); // Initial accumulator value set to 0

                                        console.log('sum', sum);

                                        // TODO: Tu je niekde chyba
                                        find.items[sum].insertText(' ' + diff[i].value, 'End');
                                        await context.sync();
                                    } else {
                                        if (find.items.length > 0) {
                                            console.log(
                                                'find -2',
                                                diff[i - 2].value,
                                                diff[i - 1].value,
                                                diff[i].value,
                                                find,
                                            );

                                            if (diff[i - 1].value == ' ') {
                                                find.items[0].insertText(
                                                    ' ' + diff[i].value,
                                                    'End',
                                                );
                                            } else {
                                                find.items[0].insertText(diff[i].value, 'End');
                                            }
                                            await context.sync();
                                        }
                                    }
                                } else {
                                    const find_second: Word.Range = sentencesWord.items[index]
                                        .search(diff[i - 1].value, {
                                            matchWholeWord: false,
                                            ignoreSpace: true,
                                            ignorePunct: true,
                                        })
                                        .getFirstOrNullObject();

                                    find_second.load('text');
                                    await context.sync();

                                    if (!find_second) {
                                        const find_third: Word.Range = sentencesWord.items[index]
                                            .search(diff[i - 1].value.split(' ')[0], {
                                                matchWholeWord: false,
                                                ignoreSpace: true,
                                                ignorePunct: true,
                                            })
                                            .getFirstOrNullObject();

                                        find_third.load('text');
                                        await context.sync();
                                    }
                                    if (find_second) {
                                        find_second.insertText(diff[i].value, 'End');
                                        await context.sync();
                                    }
                                }
                            } else {
                                // Predchadzajuce slovo bolo vymazane
                            }
                        }
                    }
                }
            };

            await runRedling();

            // ---------------------------------------
            // REFACTOR BELOW ------------------------
            // final check
            // ----------------------------------
            // add late payment fees clause
            let diff = [];

            const finalText = paragraph.load('text');
            await context.sync();

            diff = Diff.diffWordsWithSpace(finalText.text, newText);

            if (diff.length > 0) {
                for (let i = 0; i < diff.length; i++) {
                    if (diff[i].added == true) {
                        paragraph.insertText(diff[i].value, 'End');
						await context.sync();
                    }
                }
            }
        });
    },

    addContentControl: (key: string, value: string, name: string) => {
        Word.run((context) => {
            let serviceNameRange = context.document.getSelection();
            let serviceNameContentControl = serviceNameRange.insertContentControl();

            serviceNameContentControl.tag = `meta_${key}`;
            serviceNameContentControl.title = name;
            serviceNameContentControl.cannotEdit = false;
            serviceNameContentControl.insertText(value, 'Replace');
            return context.sync();
        })
            .then(() => {
                // fillAllMetas(`meta_${key}`, value)
            })
            .catch((err) => {
                console.error('Error: ', JSON.stringify(err));
            });
    },

    getDocumentProperties: () => {
        return new Promise((resolve) => {
            Word.run((context) => {
                var customDocProps = context.document.properties.customProperties;
                context.load(customDocProps);
                return context.sync().then(() => {
                    console.log('customDocProps loaded', customDocProps);
                    console.log(customDocProps);
                    let props: any = {};
                    for (let item of customDocProps.items) {
                        // console.log('customDocProp item', item);
                        props[item.key] = item.value;
                        //     if (item.key === 'project') {
                        //         this.projectId = item.value;
                        //         this.debuglog('PROJECT ' + this.projectId)
                        //         this.fetchMeta();
                        //         return;
                        //     }
                    }
                    resolve(props);
                    // this.projectNotFound = true;
                });
            });
        });
    },
};

const fillAllMetas = (key: string, value: string) => {
    Word.run(async (context) => {
        const contentControls = context.document.contentControls.load('items');
        await context.sync();

        const contentControlsArray = [];
        for (let i = 0; i < contentControls.items.length; i++) {
            if (contentControls.items[i].tag === key) {
                const paragraph = contentControls.items[i].getRange('Whole').paragraphs.getFirst();
                paragraph.load('text');
                contentControlsArray.push(paragraph);
            }
        }

        await context.sync();

        for (let i = 0; i < contentControlsArray.length; i++) {
            contentControlsArray[i].insertText(`${value}`, 'Replace');
        }

        await context.sync();
    });
};
