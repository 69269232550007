import { User } from '@contractool/schema';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '../components/Form';
import { Page } from '../components/Page';
import { AuthContext } from '../utils/auth';
import { http } from '../utils/http';
import { EnvironmentContext } from '../utils/environment';
import TutorialPage from './TutorialPage';

export function LoginPage() {
    const history = useHistory();
    const { login } = useContext(AuthContext);
    const { setEnv } = useContext(EnvironmentContext);
    const [firstRun, setFirstRun] = useState(true);

    const onSuccess = ({ user, token }: { user: User; token: string }) => {
        login(user);
        localStorage.setItem('token', token);
        history.push('/');
    };

    // Check if its first run

    useEffect(() => {
        const firstRunValue = localStorage.getItem('firstRun');
        if (firstRunValue) {
            setFirstRun(false);
        }

        if (!firstRunValue) {
            localStorage.setItem('firstRun', 'true');
        }
    }, []);

    if (firstRun) {
        return (
            <TutorialPage
                onSkip={() => {
                    setFirstRun(false);
                }}
            />
        );
    }

    return (
        <Page>
            <h1 className="text-xl leading-tight font-semibold select-none mb-20 text-gray-800 text-center">
                Sign in to Cequence
            </h1>

            <Form
                initialValues={{ email: '', password: '', web: 'https://app.cequence.com' }}
                submitOnEnter={true}
                onSubmit={(values) => {
                    setEnv(values.web);
                    return http.post('/api/auth/login?office_addin=true', values);
                }}
                onSuccess={onSuccess}
                allowPristineSubmission={true}
                guard={false}
            >
                <Form.TextInput name="web" label="Website" placeholder="App url" className="mb-6" />
                <Form.TextInput
                    name="email"
                    label="Email"
                    placeholder="your@email.com"
                    className="mb-6"
                />

                <Form.TextInput type="password" name="password" label="Password" className="mb-4" />
                <a className="text-blue-500">
                    If you forgot password or you need account, please contact your IT department or sales@cequence.io.
                </a>
                <Form.Submit className="w-full mt-10">Sign In</Form.Submit>
            </Form>
        </Page>
    );
}
