import React from 'react';
import { FilterTemplate } from '@contractool/schema';
import Field from './Field';
import { normalizeSearchText } from '../../../utils/search';

interface IFields {
    fields: FilterTemplate[];
    search: string;
}

const Fields: React.FC<IFields> = ({ fields, search }) => {
    return (
        <ul className="w-full">
            {fields
                ?.filter((item: FilterTemplate) => normalizeSearchText(item.label).includes(search))
                ?.map((item: FilterTemplate) => {
                    return (
                        <Field
                            key={item.id}
                            label={item.label}
                            value={item.name}
                            selected={''}
                            selectValue={() => {}}
                        />
                    );
                })}
        </ul>
    );
};

export default Fields;
