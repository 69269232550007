import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <div className={`py-5 select-none text-center flex-none space-y-5`}>
            <img
                className="mx-auto"
                src={require('../../assets/cequence.png')}
                alt="logo"
                style={{ height: 25 }}
            />
            <div>
                <p className="text-gray-600">&copy;{year}&nbsp; Cequence . All rights reserved.</p>
                <p className="text-gray-500 mt-2">v1.00</p>
            </div>
        </div>
    );
};

export default Footer;
