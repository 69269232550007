import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

import './assets/styles/app.css';

const Office = window.Office;

Office.onReady(() => {
    // If needed, Office.js is ready to be called
});
//
// /* Render application after Office initializes */
Office.initialize = () => {
   // ReactDOM.render(<App />, document.getElementById('root'));
};

ReactDOM.render(<App />, document.getElementById('root'));