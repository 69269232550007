import React, { FC, useContext } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext, AuthContextProvider } from './utils/auth';
import { EnvironmentContextProvider } from './utils/environment';
import { MainPage } from './pages/MainPage';
import { LoginPage } from './pages/LoginPage';
import { WorkflowPage } from './pages/WorkflowPage';
import { ToastContainer } from './components/Toast';
import { AIPage } from './pages/AIPage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function App() {
    return (
        <EnvironmentContextProvider>
            <AuthContextProvider>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login">
                            <LoginPage />
                        </Route>
                        <PrivateRoute>
                            <Switch>
                                <Route path="/" exact>
                                    <MainPage />
                                </Route>
								<Route path="/ai" exact>
                                    <AIPage />
                                </Route>
                                <Route path="/workflows/:id">
                                    <WorkflowPage />
                                </Route>
                            </Switch>
                        </PrivateRoute>
                    </Switch>
                </HashRouter>
                <ToastContainer />
            </AuthContextProvider>
        </EnvironmentContextProvider>
    );
}

export const PrivateRoute: FC = ({ children }) => {
    const { user } = useContext(AuthContext);

    let content = children;

    if (!user) {
        content = <Redirect to={{ pathname: '/login' }} />;
    }

    return <Route>{content}</Route>;
};
