import React, { useContext, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Page } from '../components/Page';
import { Tab, Tabs } from '../components/Tabs';
import { AuthContext } from '../utils/auth';
import { Workflow } from '@contractool/schema/Workflow';
import { Search } from '../components/Search';
import Roles from '../components/Workflow/Roles/Roles';
import ConditionModal from '../components/Condition/ConditionalModal';
import Fields from '../components/Workflow/Fields/Fields';
import Grids from '../components/Workflow/Grids/Grids';

export function WorkflowPage() {
    const { config } = useContext(AuthContext);

    const { id } = useParams<{ id: string }>();

    const { path } = useRouteMatch();

    const [search, setSearch] = useState('');

    const workflow = config?.workflows?.find((workflow: Workflow) => workflow.id === Number(id));

    const searchField = (phrase: string) => {
        setSearch(phrase);
    };

    const history = useHistory();

    return (
        <Page>
            <h1 className="text-lg leading-tight select-none mb-5 text-gray-800 text-left">
                Workflow - <span className="font-bold">{workflow?.label}</span>
            </h1>
            <Search placeholder={'Filter properties...'} value={search} onChange={searchField} />
            <Tabs>
                <Tab name="fields" heading="Fields" className="py-8">
                    <Fields fields={workflow?.fields?.meta_data} search={search} />
                </Tab>

                <Tab name="roles" heading="Roles" className="py-8">
                    <Roles
                        search={search}
                        roles={workflow?.roles}
                        supplierFields={config?.supplier_fields?.map((item) => {
                            return {
                                id: item.name,
                                name: item.name,
                                key: 'supplier.fields.' + item.name,
                            };
                        })}
                        supplierPOCFields={config?.supplier_poc_fields?.map((item) => {
                            return {
                                id: item.name,
                                name: item.name,
                                key: 'supplierContact.fields.' + item.name,
                            };
                        })}
                    />
                </Tab>
                <Tab name="grids" heading="Grids" className="py-8">
                    <Grids grids={workflow?.fields?.grids} search={search} />
                </Tab>
            </Tabs>
            <Switch>
                <Route path={`${path}/condition/:conditionId`} exact>
                    <ConditionModal
                        workflow={workflow}
                        onSubmit={() => {
                            history.goBack();
                        }}
                    />
                </Route>
            </Switch>
        </Page>
    );
}
