import React, { useCallback, useContext } from 'react';
import { AuthContext } from '../../utils/auth';
import { useHistory } from 'react-router-dom';
import { Avatar } from '../Avatar';
import { Icon } from '../Icon';
import { Menu, MenuItem } from '../Menu/Menu';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);

    const history = useHistory();

    const signOut = useCallback(() => {
        logout();
    }, [logout]);

    if (!user) return null;

    return (
        <div className="flex justify-between w-full items-center px-6 pt-5 border-b-2 border-gray-200 pb-3">
            <div className="text-left">
                <div className="flex items-center">
                    <span className="cursor-pointer mr-2" onClick={() => history.goBack()}>
                        <Icon name="arrow_back" size={5} className="text-gray-600" />
                    </span>
                    <h2 className="text-sm">{user.name}</h2>
                </div>
            </div>
            <div aria-label="User section" className="select-none flex items-center cursor-pointer">
                <Menu
                    handle={({ toggleMenu }) => (
                        <div onClick={toggleMenu} className="flex items-center cursor-pointer">
                            <Avatar user={user} className="w-8 h-8 bg-gray-200" />

                            <Icon
                                name="keyboard_arrow_down"
                                className="text-gray-600 fill-black ml-1"
                                size={6}
                            />
                        </div>
                    )}
                >
                    <MenuItem icon="exit_to_app" onClick={signOut}>
                        Sign out
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default Navbar;
