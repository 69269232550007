import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { useToasts } from '../components/Toast';
import { ENV_KEY } from './environment';

const axios = Axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Release': localStorage.getItem('_app_version'),
    },
});

export function registerAppVersionInterceptor(callback: (beVersion: string) => void) {
    axios.interceptors.response.use((response) => {
        callback(response.headers['x-application-release']);

        return response;
    });
}

export function registerErrorResponseInterceptor(callback: (response: AxiosResponse) => void) {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            callback(error.response);
            return Promise.reject(error);
        },
    );
}

export interface Response<T> {
    data: T;
    meta?: any;
}

interface Params {
    [name: string]: string;
}

export const http = {
    get<T>(
        url: string,
        params: Params = {},
        options: AxiosRequestConfig = {},
    ): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);

        axios.interceptors.request.use((config) => {
            config.paramsSerializer = (params) => {
                return qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encode: false,
                });
            };
            return config;
        });
        // add token to header
        if (localStorage.getItem('token')) {
            options.headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
        }

        let opt = Object.assign({}, options, params);
        return axios.get<Response<T>>(instance + url, opt).then((response) => response.data);
    },

    head<T>(url: string, data?: any): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);
        return axios.head<Response<T>>(instance + url, data).then((response) => response.data);
    },

    patch<T>(url: string, data?: any): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);
        return axios
            .patch<Response<T>>(instance + url, data, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
            .then((response) => response.data);
    },

    post<T>(url: string, data?: any): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);
        return axios
            .post<Response<T>>(instance + url, data, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
            .then((response) => {
                return response.data;
            });
    },

    put<T>(url: string, data?: any): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);
        return axios
            .put<Response<T>>(instance + url, data, { withCredentials: true })
            .then((response) => response.data);
    },

    delete<T>(url: string): Promise<Response<T>> {
        const instance = localStorage.getItem(ENV_KEY);
        return axios
            .delete<Response<T>>(instance + url, { withCredentials: true })
            .then((response) => response.data);
    },
};

if (process.env.NODE_ENV !== 'production') {
    const { error } = useToasts();
    let toastError = error;
    axios.interceptors.response.use(
        (response) => {
            if (
                response.status === 200 &&
                typeof response.data === 'string' &&
                response.data?.includes('window.Sfdump')
            ) {
                renderDebugView(response.data);
            }

            return response;
        },
        (error) => {
            if (error.response?.status === 500 && error.response?.data?.html) {
                renderDebugView(error.response.data.html);
            }
            if (error.response?.status === 423) {
                toastError(error.response?.data?.message);
            }

            return Promise.reject(error);
        },
    );
}

function renderDebugView(html: string): void {
    const doc = document.open('text/html', 'replace');
    doc.write(html);
    doc.close();
}
