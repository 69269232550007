import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '../Modal';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../utils/auth';
import { Workflow } from '@contractool/schema/Workflow';

type Props = {
    onSubmit: () => void;
    workflow: Workflow;
};

const ConditionModal: React.FC<Props> = ({ onSubmit, workflow }) => {
    const { config } = useContext(AuthContext);

    const { conditionId } = useParams<{ conditionId: string }>();

    const [field, setField] = useState(null);
	const [fieldType, setFieldType] = useState(null)

    useEffect(() => {
        if (conditionId && config && workflow?.fields.meta_data) {
            if (conditionId.includes('supplier')) {
                setField(config.supplier_fields.find((field) => field.name === conditionId));
				setFieldType('input')
            } else if (conditionId.includes('supplier_poc')) {
                setField(config.supplier_poc_fields.find((field) => field.name === conditionId));
				setFieldType('input')
            } else if (conditionId.includes('team')) {
                setField(workflow.roles.find((field) => conditionId.includes(field.full_key)));
				setFieldType('input')
            } else {
                setField(workflow?.fields.meta_data.find((field) => field.name === conditionId));
            }
        }
    }, [config, conditionId, workflow?.fields.meta_data]);

	if(!field) return null;
    return (
        <Modal
            onClose={() => {
                onSubmit();
            }}
            heading="Condition"
            isOpen={true}
        >
            <div className="w-2/5">
				{/* <Select */}
                {/* {type &&
          condition.operation !== 'EMPTY' &&
          condition.operation !== 'NOT_EMPTY' &&
          condition.operation !== 'IN_LAST' &&
          condition.operation !== 'IN_FOLLOWING' && (
            <InputTypeSwitch
              name={`values_${condition.id}`}
              value={condition.value}
              options={values}
              props={props}
              type={type}
              onChange={(value) => {
                dispatch({
                  type: 'UPDATE_CONDITION_VALUES',
                  id: condition.id,
                  value,
                });
                onChange('value');
              }}
              hasError={getError('value')}
            />
          )}
        {type &&
          (condition.operation === 'IN_LAST' ||
            condition.operation === 'IN_FOLLOWING') && (
            <InputTypeSwitch
              name={`values_${condition.id}`}
              value={condition.value}
              options={[
                {
                  label: translate('Years'),
                  value: 'YEARS',
                },
                {
                  label: translate('Months'),
                  value: 'MONTHS',
                },
                {
                  label: translate('Weeks'),
                  value: 'WEEKS',
                },
                {
                  label: translate('Days'),
                  value: 'DAYS',
                },
                {},
              ]}
              props={props}
              type={condition.operation}
              onChange={(value) => {
                dispatch({
                  type: 'UPDATE_CONDITION_VALUES',
                  id: condition.id,
                  value,
                });
                onChange('value');
              }}
              hasError={getError('value')}
            />
          )} */}
            </div>
        </Modal>
    );
};

export default ConditionModal;
