import React, { FC } from 'react';
import { User } from '@contractool/schema';
import { sign } from '../utils/auth';

export const Avatar: FC<{ user: User; className?: string }> = ({ user, className }) => {
    return (
        <div
            key={user.id}
            style={{ backgroundImage: `url(${sign(user.avatar_url)})` }}
            className={`rounded-full ${className} bg-center bg-cover bg-no-repeat`}
        ></div>
    );
};

export const AvatarLink: FC<{ avatar_url: string; name: string; className?: string }> = ({
    avatar_url,
    name,
    className,
}) => {
    return (
        <img key={name} src={sign(avatar_url)} alt={name} className={`rounded-full ${className}`} />
    );
};
