// @ts-nocheck

import React from 'react';
import { Role } from '@contractool/schema';
import { Tree } from 'react-arborist';

import { normalizeSearchText } from '../../../utils/search';
import { Node } from './Node';

interface Props {
    roles: Role[];
    supplierFields: {
        label: string;
        name: string;
    };
    supplierPOCFields: {
        label: string;
        name: string;
    };
    search: string;
}

const Roles: React.FC<Props> = ({ roles, supplierFields, supplierPOCFields, search }) => {
    let data = roles.map((role: Role) => {
        return {
            id: role.key,
            key: role.full_key,
            name: role.label,
            children: [
                {
                    id: role.full_key + '.name',
                    name: 'Name',
                    key: role.full_key + '.name',
                },
                {
                    id: role.full_key + '.fields.gender',
                    name: 'Gender',
                    key: role.full_key + '.fields.gender',
                },
            ],
        };
    });

    data = [
        ...data,
        {
            id: 'supplier',
            key: 'supplier',
            name: 'Suppliers',
            children: [
                {
                    id: 'supplier.title',
                    name: 'Title',
                    key: 'supplier.title',
                },
                {
                    id: 'supplier.address_city',
                    name: 'City',
                    key: 'project.supplier.address_city',
                },
                {
                    id: 'supplier.address_street',
                    name: 'Street',
                    key: 'supplier.address_street',
                },
                {
                    id: 'supplier.address_state',
                    name: 'State',
                    key: 'supplier.address_state',
                },
                {
                    id: 'supplier.address_zip',
                    name: 'Zip',
                    key: 'supplier.address_zip',
                },
                ...supplierFields,
            ],
        },
        {
            id: 'supplierPOC',
            key: 'supplierPOC',
            name: 'Supplier POC',
            children: [
                {
                    id: 'supplierContact.name',
                    name: 'Name',
                    key: 'supplierContact.name',
                },
                {
                    id: 'supplierContact.email',
                    name: 'Email',
                    key: 'supplierContact.email',
                },
                ...supplierPOCFields,
            ],
        },
    ];

    // @ts-ignore
    return (
        <Tree
            rowHeight={40}
            searchTerm={search}
            searchMatch={(node, term) =>
                normalizeSearchText(node.data.name).includes(normalizeSearchText(term))
            }
            openByDefault={false}
            initialData={data}
        >
            {Node}
        </Tree>
    );
};

export default Roles;
