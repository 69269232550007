import React from 'react';
import { Tree } from 'react-arborist';
import { normalizeSearchText } from '../../../utils/search';
import { Node } from './Node';

type Grid = {
    label: string;
    key: string;
    type: string;
    name: string;
    cols?: Grid[];
};

interface IProps {
    grids: Grid[];
    search: string;
}

const Grids: React.FC<IProps> = ({ grids, search }) => {
    let data = grids.map((role: Grid, index: number) => {
        return {
            id: role.key + index,
            key: role.key,
            name: role.label,
            children:
                role.cols?.map((col, i) => {
                    return {
                        id: col.name + i + index,
                        key: col.name,
                        name: col.label,
                    };
                }) || [],
        };
    });

    return (
        <Tree
            key="grids"
            searchTerm={search}
            searchMatch={(node, term) =>
                normalizeSearchText(node.data.name).includes(normalizeSearchText(term))
            }
            rowHeight={40}
            openByDefault={false}
            initialData={data}
        >
            {Node}
        </Tree>
    );
};

export default Grids;
