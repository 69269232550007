import React, { FC, ReactNode, useState, ReactElement, Children, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

type Tab = {
    name: string;
    heading: ReactNode | ((props: { active: boolean }) => ReactNode);
    children?: ReactNode;
    className?: string;
};

export const Tabs: FC<{
    selected?: string;
    onSelect?: (name: string) => void;
    className?: string;
}> = ({ children, className, onSelect, selected: initial }) => {
    const tabs = Children.map(children || [], (child) => (child as ReactElement<Tab>).props);
    const [selected, setSelected] = useState(initial || (tabs[0] && tabs[0].name) || undefined);

    useEffect(() => {
        initial && setSelected(initial);
    }, [initial]);

    return (
        <>
            <TabList
                tabs={tabs}
                selected={selected}
                onClick={(tab) => {
                    setSelected(tab.name);
                    onSelect && onSelect(tab.name);
                }}
                className={className}
            />

            {tabs.map((tab, i) => {
                return selected === tab.name && <TabPanel key={i} tab={tab} />;
            })}
        </>
    );
};

export const RouteTabs: FC<{
    onSelect?: (name: string) => void;
    className?: string;
    match: string;
}> = ({ children, onSelect, match, className }) => {
    const tabs = Children.map(children || [], (child) => (child as ReactElement<Tab>).props);

    const history = useHistory();
    const route = useRouteMatch<{ tab: string }>({ path: match });

    const url = (tab: Tab) => match.replace(':tab', tab.name);

    return (
        <>
            <TabList
                tabs={tabs}
                selected={route?.params.tab}
                onClick={(tab) => {
                    if (onSelect) {
                        onSelect(tab.name);
                    } else {
                        history.push(url(tab));
                    }
                }}
                className={className}
            />

            <Switch>
                {tabs.map((tab, i) => (
                    <Route path={url(tab)} key={i}>
                        <TabPanel tab={tab} />
                    </Route>
                ))}

                <Route>
                    <Redirect to={url(tabs[0])} />
                </Route>
            </Switch>
        </>
    );
};

const TabList: FC<{
    tabs: Tab[];
    selected: string | undefined;
    onClick: (tab: Tab) => void;
    className?: string;
}> = ({ tabs, selected, onClick, className }) => {
    return (
        <ul className={`flex border-b h-14 ${className || ''}`} role="tablist">
            {tabs.map((tab, i) => {
                return (
                    <li className="h-14" key={i}>
                        <button
                            role="tab"
                            onClick={() => onClick(tab)}
                            className={`relative h-14 py-3 cursor-pointer border-b-2 select-none focus:outline-none focus:border-blue-700 hover:border-blue-700 ${
                                selected === tab.name
                                    ? 'text-gray-800 border-blue-700'
                                    : 'text-gray-600 border-transparent-000'
                            } ${tabs.length - 1 === i ? '' : 'mr-10'}`}
                            id={aria_tab(tab.name)}
                            aria-controls={aria_panel(tab.name)}
                            aria-selected={selected === tab.name}
                            data-cy-tab={tab.name}
                            type="button"
                        >
                            {typeof tab.heading === 'function'
                                ? tab.heading({ active: selected === tab.name })
                                : tab.heading}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

const TabPanel: FC<{ tab: Tab }> = ({ tab }) => {
    if (!tab.children) {
        return null;
    }

    return (
        <div
            role="tabpanel"
            className={tab.className}
            id={aria_panel(tab.name)}
            aria-labelledby={aria_tab(tab.name)}
        >
            {tab.children}
        </div>
    );
};

export const Tab: FC<Tab> = ({ children }) => {
    return <>{children}</>;
};

function aria_panel(tab: string): string {
    return `tabs-${tab}-panel`;
}

function aria_tab(tab: string): string {
    return `tabs-${tab}-tab`;
}
