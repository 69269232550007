import React, { FC } from 'react';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
// import { ReactComponent as Logo } from '../assets/cequence.svg';

export const Page: FC<{
    logo?: boolean;
    bottomText?: boolean;
    paddingHorizontal?: string;
    paddingVertical?: string;
    center?: boolean;
    grow?: boolean;
}> = ({ grow = false, children }) => {
    return (
        <div className="flex h-full flex-col justify-between items-center overflow-y-auto">
            <Navbar />
            <div
                className="flex flex-col justify-start flex-auto py-5 px-8 w-full"
            >
                <div className={`${grow ? 'flex-grow' : ''}`}>{children}</div>
            </div>
            <Footer />
        </div>
    );
};
