import React, { FC, ReactNode, useState, createContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { createPortal } from 'react-dom';

type Props = Omit<ReactModal.Props, 'isOpen'> & {
    isOpen?: boolean;
    onClose: () => void;

    heading?: ReactNode;
    compact?: boolean;
    size?: string;
    corner?: ReactNode;
    contentClassName?: string;
    draggable?: boolean;
    containerStyle?: Object;
};

type Footer = FC<{ className?: string }>;

const ModalContext = createContext<{
    footerRef: HTMLDivElement | null;
}>({
    footerRef: null,
});

const defaultContainerStyle = {
    maxHeight: 'calc(100vh - 60px)',
};

const overlayClassName = {
    base:
        'fixed inset-0 flex items-center justify-center p-10 bg-transparent-500 opacity-0 transition',
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0',
};

export const Modal: FC<Props> & { Footer: Footer } = ({
    children,
    heading,
    corner,
    compact = false,
    size = 'regular',
    isOpen = true,
    onClose,
    contentClassName,
    draggable = false,
    containerStyle,
    ...rest
}) => {
    const [footerRef, setFooterRef] = useState<HTMLDivElement | null>(null);
    const [hasFooter, setHasFooter] = useState(false);
    if (size === 'regular') {
        size = 'w-232';
    }
    if (size === 'small') {
        size = 'w-192';
    }
    useEffect(() => {
        footerRef && setHasFooter(footerRef.children.length > 0);
    }, [footerRef]);

    const reactModalClassName = React.useMemo(
        () => ({
            base: `outline-none ${size}`,
            afterOpen: 'opacity-100',
            beforeClose: 'opacity-0',
        }),
        [size],
    );

    const customStyles = {
        content: {
            width: size === 'large' ? 1024 : '',
        },
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={reactModalClassName}
            overlayClassName={overlayClassName}
            style={customStyles}
            {...rest}
        >
            <div
                className="flex flex-col bg-white border rounded-xl transition"
                style={containerStyle || defaultContainerStyle}
            >
                {compact && (
                    <div
                        className={`${
                            draggable ? 'handle cursor-move' : ''
                        } flex justify-between items-center py-7 border-b border-gray-100 px-16`}
                    >
                        {heading && <h2 className="text-gray-700 text-lg">{heading}</h2>}
                        {heading === '' && corner && <div />}
                        {corner && <div>{corner}</div>}
                    </div>
                )}

                <div className="flex-1 overflow-auto">
                    {!compact && (
                        <div
                            className={`${
                                draggable ? 'handle cursor-move' : ''
                            } flex items-center justify-between px-16 py-12`}
                        >
                            {heading && (
                                <h2 className="text-gray-700 text-xl break-all" id="modal-title">
                                    {heading}
                                </h2>
                            )}
                            {heading === '' && corner && <div />}
                            {corner && <div>{corner}</div>}
                        </div>
                    )}

                    <div
                        className={`px-16 ${hasFooter ? '' : 'pb-16'} ${
                            compact ? 'pt-12' : ''
                        } ${contentClassName}`}
                    >
                        <ModalContext.Provider value={{ footerRef }}>
                            {children}
                        </ModalContext.Provider>
                    </div>
                </div>

                <div className={hasFooter ? 'relative py-16 mx-16' : undefined}>
                    {hasFooter && <div className="absolute left-0 top-0 w-full h-px bg-gray-200" />}
                    <div ref={setFooterRef} />
                </div>
            </div>
        </ReactModal>
    );
};

const ModalFooter: Footer = ({ children, className }) => {
    return (
        <ModalContext.Consumer>
            {({ footerRef }) =>
                footerRef
                    ? createPortal(<div className={className}>{children}</div>, footerRef)
                    : null
            }
        </ModalContext.Consumer>
    );
};

Modal.Footer = ModalFooter;
