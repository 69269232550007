import React, { FC, createContext } from 'react';
import useLocalStorage from '@rehooks/local-storage';

export const ENV_KEY = 'environment';

export const EnvironmentContext = createContext<{
    env: string | null;
    setEnv: (env: string) => void;
    unsetEnv: () => void;
}>({
    env: null,
    setEnv: () => {},
    unsetEnv: () => {},
});

export const EnvironmentContextProvider: FC = ({ children }) => {
    const value = Object.assign({}, useEnvironmentHandler());
    return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
};

const useEnvironmentHandler = () => {
    const [env, setEnvironment, unsetEnvironment] = useLocalStorage<string>(ENV_KEY);

    return {
        env,

        setEnv(env: string) {
            setEnvironment(env);
        },

        unsetEnv() {
            unsetEnvironment();
        },
    };
};
