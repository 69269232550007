import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';
import { Page } from '../components/Page';
import { AuthContext } from '../utils/auth';
import { Workflow } from '@contractool/schema/Workflow';

export function MainPage() {
    const { config } = useContext(AuthContext);

    return (
        <Page>
            <h1 className="text-xl leading-tight select-none mb-20 text-gray-800 text-left">
                Select workflow or use tools
            </h1>
            {config &&
                config?.workflows?.map((workflow: Workflow) => {
                    return (
                        <Link to={`/workflows/${workflow.id}`}>
                            <Button key={workflow.id} className="mb-5 w-full">
                                <Icon name="world" className="mr-2" size={6} />
                                {workflow.label}
                            </Button>
                        </Link>
                    );
                })}
            <hr className='my-4' />
            <Link to={`/ai`}>
                <Button className="mt-5 w-full">
                    <Icon name="person_plus" className="mr-2" size={6} />
                    AI tools
                </Button>
            </Link>
        </Page>
    );
}
