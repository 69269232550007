import { useState } from 'react';
import { http } from '../http';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Axios from 'axios';
import omitBy from 'lodash/omitBy';

export function useRequest<T, M = unknown>(
    url: string,
    defaultValue: T,
    {
        params = {},
    }: {
        params?: Record<string, any>;
    } = {},
    setErrorResponse?: (value: any) => void,
): [
    T,
    {
        meta: M | undefined;

        refresh: () => void;
    }
    ] {

    const [state, setState] = useState(defaultValue);
    const [meta, setMeta] = useState<M | undefined>(undefined);
    const [refreshToken, setRefreshToken] = useState(false);

    useDeepCompareEffect(() => {
        const source = Axios.CancelToken.source();

        http.get<T>(
            url,
            omitBy(params || {}, (v) => v === undefined || v === null || v === ''),
            {cancelToken: source.token},
        )
            .then((response) => {
                if (response.meta) {
                    setMeta(response.meta);
                }
                setState(response.data);
            })
            .catch((e) => {
                if (Axios.isCancel(e)) {
                    return;
                }

                if (e?.response?.status === 401) {
                    if (setErrorResponse) {
                        setErrorResponse(e.response.data);
                    }
                    return e;
                }

                throw e;
            });

        return () => source.cancel();
    }, [url, params, refreshToken]);

    return [
        state,

        {
            meta,

            refresh() {
                setRefreshToken(!refreshToken);
            },
        },
    ];
}
